var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-text',[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"label":_vm.$t('lbl.itemsPerPage'),"type":"number","min":"5","hide-details":"","dense":"","outlined":""},on:{"input":function($event){return _vm.load()}},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":_vm.$t('btn.search'),"single-line":"","hide-details":"","dense":"","outlined":""},on:{"input":function($event){return _vm.load()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-divider'),_c('v-form',{staticClass:"multi-col-validation px-5 py-5"},[_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"12"}},[_vm._v(" "+_vm._s(_vm.totalF)+" "+_vm._s(_vm.$t('lbl.de'))+" "+_vm._s(_vm.totalItems)+" "+_vm._s(_vm._f("lowercase")(_vm.$t('menu.types')))+" ")])],1)],1),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('lbl.name'))+" ")]),_c('th',{staticClass:"text-uppercase",attrs:{"width":"60%"}},[_vm._v(" "+_vm._s(_vm.$t('lbl.description'))+" ")]),(_vm.permisos.includes('tipo_afiliado:edit') || _vm.permisos.includes('*'))?_c('th',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('lbl.actions'))+" ")]):_vm._e()])]),_c('tbody',_vm._l((_vm.items),function(iten,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(iten.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(iten.description)+" ")]),(
              _vm.permisos.includes('tipo_afiliado:edit') ||
                _vm.permisos.includes('tipo_afiliado:destroy') ||
                _vm.permisos.includes('*')
            )?_c('td',[(_vm.permisos.includes('tipo_afiliado:edit'))?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(iten)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSquareEditOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.edit')))])]):_vm._e(),(
                _vm.permisos.includes('tipo_afiliado:destroy') &&
                  iten.slug !== 'propios' &&
                  iten.slug !== 'tipo-a' &&
                  iten.slug !== 'tipo-b' &&
                  iten.slug !== 'tipo-c' &&
                  iten.slug !== 'tipo-d' &&
                  iten.slug !== 'tipo-e' &&
                  iten.slug !== 'tipo-f'
              )?_c('v-tooltip',{attrs:{"top":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(iten)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])]):_vm._e()],1):_vm._e()])}),0)]},proxy:true}])}),_c('v-card-text',{staticClass:"pt-2"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"lg":"12","cols":"12"}},[_c('v-pagination',{attrs:{"length":_vm.pagination.total,"total-visible":"5"},on:{"input":_vm.onPageChange},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}})],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","max-width":"350px"},model:{value:(_vm.isDialogVisibleDelete),callback:function ($$v) {_vm.isDialogVisibleDelete=$$v},expression:"isDialogVisibleDelete"}},[_c('v-card',{attrs:{"max-height":"400px"}},[_c('v-card-title',[_vm._v(_vm._s(((_vm.$t('btn.delete')) + " " + (_vm.item.name))))]),_c('v-divider'),_c('v-card-text',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.$t('msg.deleteItem', { n: _vm.item.name }))+" ")]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.isDialogVisibleDelete = !_vm.isDialogVisibleDelete}}},[_vm._v(" "+_vm._s(_vm.$t('btn.close'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":"","loading":_vm.loading},on:{"click":function($event){return _vm.confirmDelete()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.send'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }